import React from "react";
import styles from "./ProgressLabel.module.scss";
import { useTypedSelector } from "../../cores/useTypedSelector";

const ProgressLabel: React.FC<{}> = () => {
  const { progressLabel } = useTypedSelector(
    ({ reconciliation: { progressLabel } }) => ({
      progressLabel
    })
  );
  return <div className={styles.label}>{progressLabel}</div>;
};

export default ProgressLabel;
