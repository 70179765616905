import React, {useEffect} from "react";
import {ReactComponent as DownloadIcon} from "../../images/ic-download-b.svg";
import styles from "./ExcelDownButton.module.scss";
import Ink from "react-ink";
import {ReconciliationActions, ReconciliationTypes} from "../../store/reconciliation";
import {useRouter} from "../../cores/useRouter";
import {useTypedSelector} from "../../cores/useTypedSelector";
import {get} from "lodash";
import {useDispatch} from "react-redux";
import CircleLoader from "../CircleLoader/CircleLoad";

const ExcelDownButton: React.FC<{}> = () => {
  const { query } = useRouter();
  const month = query.get("month");
  const dispatch = useDispatch();

  const { resultFiles, getResultFilesLoading } = useTypedSelector(
    ({ reconciliation: { resultFiles }, loading: { asyncMap } }) => ({
      resultFiles,
      getResultFilesLoading:
        get(asyncMap, ReconciliationTypes.getResultFiles) > 0
    })
  );

  const fileUrl = get(resultFiles, "file_url");

  useEffect(() => {
    if (fileUrl) {
      const aTag = document.createElement("a");
      aTag.setAttribute("href", fileUrl);
      aTag.setAttribute("download", "#");
      aTag.style.display = "none";
      document.body.appendChild(aTag);
      aTag.click();
      document.body.removeChild(aTag);
      dispatch(ReconciliationActions.truncateResultFiles());
    }
  }, [fileUrl]);

  const onClickDownButton = () => {
    if (month) {
      dispatch(ReconciliationActions.getResultFiles(month));
    }
  };

  return (
    <div className={styles.excelDownButton} onClick={onClickDownButton}>
      {getResultFilesLoading ? (
        <CircleLoader />
      ) : (
        <>
          <DownloadIcon />
          엑셀 다운로드
          <Ink />
        </>
      )}
    </div>
  );
};

export default ExcelDownButton;
