import React from "react";
import { Route, Switch } from "react-router-dom";
import Reconciliation from "./pages/Reconciliation";
import ToastMessage from "./components/ToastMessage/ToastMessage";
import { getCookieToken } from "./cores/getCookieToken";
import { useRouter } from "./cores/useRouter";
import base64url from "base64-url";

function App() {
  const token = getCookieToken();
  if (!token) {
    const previousUrl = window.location.href;
    const previous = base64url.encode(previousUrl);

    window.location.replace(
      `${process.env.REACT_APP_LOGIN_URL}?previous=${previous}`
    );

    return null;
  }

  return (
    <>
      <Switch>
        <Route path="/" children={<Reconciliation />} />
      </Switch>
      <ToastMessage />
    </>
  );
}

export default App;
