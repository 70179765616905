import React from "react";
import { map } from "lodash";
import { Delay } from "../../store/reconciliation/interface";
import InconsistencyRow from "../InconsistencyRow/InconsistencyRow";
import styles from "./InconsistencyTable.module.scss";
import classNames from "classnames";

interface Props {
  inconsistencies: Delay[];
  className?: string;
}

const InconsistencyTable: React.FC<Props> = ({
  inconsistencies,
  className
}) => {
  return (
    <>
      <form>
        <table className={classNames(styles.table, className)}>
          <thead>
            <tr>
              <th rowSpan={2} style={{ width: "10%" }}>
                차량번호
              </th>
              <th colSpan={5} style={{ width: "30%" }}>
                어드민
              </th>
              <th
                colSpan={4}
                className={styles.thStrongBorder}
                style={{ width: "30%" }}
              >
                적격증빙
              </th>
              <th
                rowSpan={2}
                className={styles.thStrongBorder}
                style={{ width: "5%" }}
              >
                차액
              </th>
              <th rowSpan={2} style={{ width: "5%" }}>
                당월기준 차액
              </th>
              <th
                rowSpan={2}
                className={styles.thStrongBorder}
                style={{ width: "30%" }}
              >
                메모
              </th>
            </tr>
            <tr>
              <th>차량상태</th>
              <th>수수료</th>
              <th>입금여부</th>
              <th>거래일시</th>
              <th>입금일시</th>
              <th className={styles.thStrongBorder}>유형</th>
              <th>작성/거래 일자</th>
              <th>발행일시</th>
              <th>수수료</th>
            </tr>
          </thead>
          <tbody>
            {map(inconsistencies, (inconsistency, index) => (
              <InconsistencyRow
                inconsistency={inconsistency}
                key={inconsistency.id}
                index={index}
              />
            ))}
          </tbody>
        </table>
      </form>
    </>
  );
};

export default InconsistencyTable;
