import produce from 'immer';
import { Action, handleActions } from 'redux-actions';
import { action } from 'typesafe-actions';
import axios from 'axios';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';

export enum AuthenticationTypes {
  setToken = '@authentication/setToken',
  setLogin = '@authentication/setLogin',
  setLogout = '@authentication/setLogout'
}

export interface AuthenticationState {
  token: string | null;
  isInitialized: boolean;
}

export const AuthenticationActions = {
  setToken: (token: string | null) => action(AuthenticationTypes.setToken, token),
  setLogin: createAsyncSagaAction(AuthenticationTypes.setLogin, (data: { username: string; password: string }) =>
    axios.post('/login/', data)
  ),
  setLogout: () => action(AuthenticationTypes.setLogout)
};

const initialState: AuthenticationState = {
  token: null,
  isInitialized: false
};

export default handleActions<AuthenticationState, any>(
  {
    [AuthenticationTypes.setToken]: (state, action: Action<AuthenticationState['token']>) => {
      return produce(state, draft => {
        draft.token = action.payload;
        draft.isInitialized = true;
      });
    },
    [AuthenticationTypes.setLogout]: (state, action: Action<AuthenticationState['token']>) => {
      return produce(state, draft => {
        draft.token = null;
        draft.isInitialized = false;
      });
    }
  },
  initialState
);
