import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Memo.module.scss";
import Dialog from "../Dialog/Dialog";
import categories from "../../variables/categories";
import TextareaField from "../TextareaField/TextareaField";
import { ReconciliationActions } from "../../store/reconciliation";
import { trim } from "lodash";
import { useDispatch } from "react-redux";

interface Props {
  category: string | null;
  memo: string | null;
  memoKey?: string;
  id?: number;
}
const Memo: React.FC<Props> = ({ category, memo, memoKey, id }) => {
  const [memoTextAreaHeight, setMemoTextAreaHeight] = useState("26px");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (textAreaRef.current) {
      setMemoTextAreaHeight(`${textAreaRef.current.scrollHeight}px`);
    }
  }, []);

  const onKeyPressTextArea = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13) {
      if (!e.shiftKey) {
        e.preventDefault();
        e.currentTarget.blur();
      }
    }
  };

  const onChangeDialog = useCallback(
    (value: any) => {
      dispatch(
        ReconciliationActions.setReconciliationMemo({
          key: memoKey,
          car: id,
          category: value
        })
      );
    },
    [memoKey]
  );

  const onBlurMemo = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      const value = trim(e.currentTarget.value);
      if (value !== memo) {
        dispatch(
          ReconciliationActions.setReconciliationMemo({
            key: memoKey,
            car: id,
            content: e.currentTarget.value
          })
        );
      }
    },
    [memo, memoKey]
  );

  return (
    <div className={styles.memo}>
      <div className={styles.categoryWrapper}>
        <Dialog
          defaultValue={category}
          options={categories}
          onChange={onChangeDialog}
        />
      </div>
      <TextareaField
        ref={textAreaRef}
        style={{ height: memoTextAreaHeight }}
        defaultValue={memo ? memo : undefined}
        onBlur={onBlurMemo}
        onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
          e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
        }}
        onKeyDown={onKeyPressTextArea}
      />
    </div>
  );
};

export default Memo;
