import React, { forwardRef, HTMLAttributes, Ref } from "react";
import styles from "./TextareaField.module.scss";
import classNames from "classnames";

interface Props {
  name?: string;
  label?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
}

function TextareaField(
  {
    name,
    label,
    className,
    inputClassName,
    labelClassName,
    ...otherProps
  }: Props & HTMLAttributes<HTMLTextAreaElement>,
  ref: Ref<HTMLTextAreaElement>
) {
  return (
    <label className={classNames(styles.textareaField, className)}>
      {label && (
        <span className={classNames(styles.label, labelClassName)}>
          {label}
        </span>
      )}
      <textarea
        className={classNames(styles.input, inputClassName)}
        name={name}
        ref={ref}
        {...otherProps}
      />
    </label>
  );
}

export default forwardRef(TextareaField);
