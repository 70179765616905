import React from "react";
import styles from "./ReconciliationHeader.module.scss";
import { get } from "lodash";
import SumLabel from "../SumLabel/SumLabel";
import { toLocaleString } from "../../cores/toLocaleString";
import Row from "../Row/Row";
import { Summary } from "../../store/reconciliation/interface";
import classNames from "classnames";
import ExcelDownButton from "../ExcelDownButton/ExcelDownButton";
import getPlusSign from "../../cores/getPlusSign";

interface Props {
  summary: Summary;
}

const ReconciliationHeader: React.FC<Props> = ({ summary }) => {
  const feeSum = get(summary, "fee_sum");
  const amountSum = get(summary, "amount_sum");
  const diffSum = get(summary, "diff_sum");
  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <div>
          <Row>
            <SumLabel label="어드민 수수료 합계" subLabel="*입금일 기준" />
            <span>{toLocaleString(feeSum)} 원</span>
          </Row>
          <Row>
            <SumLabel
              label="적격증빙 수수료 합계"
              subLabel="*작성/거래일 기준"
            />
            <span>{toLocaleString(amountSum)} 원</span>&nbsp;
            <span
              className={classNames(diffSum > 0 ? styles.plus : styles.minus)}
            >
              ({getPlusSign(diffSum)}&nbsp;{toLocaleString(diffSum)} 원)
            </span>
          </Row>
        </div>
        <ExcelDownButton />
      </div>
    </header>
  );
};

export default ReconciliationHeader;
