import React from "react";
import { Delay as IDelay } from "../../store/reconciliation/interface";
import styles from "./Delay.module.scss";
import Row from "../Row/Row";
import SumLabel from "../SumLabel/SumLabel";
import getPlusSign from "../../cores/getPlusSign";
import { toLocaleString } from "../../cores/toLocaleString";
import InconsistencyTable from "../InconsistencyTable/InconsistencyTable";

interface Props {
  delays: IDelay[];
  diffByDelays?: number;
}

const Delay: React.FC<Props> = ({ delays, diffByDelays }) => {
  return (
    <div className={styles.delay}>
      <div className={styles.title}>지연 해소 건</div>
      <Row>
        <SumLabel label="당월기준 차액 합계" subLabel="*작성/거래일 기준" />
        {getPlusSign(diffByDelays)}
        {toLocaleString(diffByDelays)}원
      </Row>
      <InconsistencyTable inconsistencies={delays} className={styles.delayTable}/>
    </div>
  );
};

export default Delay;
