import { all, take, race, put, delay } from "redux-saga/effects";
import { createAsyncSaga } from "../cores/createAsyncSaga";
import {
  ReconciliationActions,
  ReconciliationTypes
} from "../store/reconciliation";

export default function* rootSaga() {
  yield all([
    ...createAsyncSaga(ReconciliationActions),
    watchSyncReconciliation(),
    watchSetReconciliationMemo(),
    watchMapReconciliation()
  ]);
}

function* watchSyncReconciliation() {
  while (true) {
    yield take([ReconciliationTypes.syncReconciliation + "/INITIAL"]);
    yield put(ReconciliationActions.setProgressLabel("동기화 중..."));
    const { syncReconciliationError } = yield race({
      success: take(ReconciliationActions.syncReconciliation.success),
      syncReconciliationError: take(
        ReconciliationActions.syncReconciliation.failure
      )
    });

    if (syncReconciliationError) {
      yield put(ReconciliationActions.setProgressLabel("동기화 실패"));
      yield delay(700);
      yield put(ReconciliationActions.truncateProgressLabel());
      return;
    }
    yield put(ReconciliationActions.setProgressLabel("동기화되었습니다."));
    yield delay(700);
    yield put(ReconciliationActions.truncateProgressLabel());
  }
}

function* watchSetReconciliationMemo() {
  while (true) {
    yield take([ReconciliationTypes.setReconciliationMemo + "/INITIAL"]);
    yield put(ReconciliationActions.setProgressLabel("저장중..."));
    const { setReconciliationMemoError } = yield race({
      success: take(ReconciliationActions.setReconciliationMemo.success),
      setReconciliationMemoError: take(
        ReconciliationActions.setReconciliationMemo.failure
      )
    });

    if (setReconciliationMemoError) {
      yield put(ReconciliationActions.setProgressLabel("저장 실패"));
      yield delay(700);
      yield put(ReconciliationActions.truncateProgressLabel());
      return;
    }
    yield put(ReconciliationActions.setProgressLabel("저장되었습니다."));
    yield delay(700);
    yield put(ReconciliationActions.truncateProgressLabel());
  }
}

function* watchMapReconciliation() {
  while (true) {
    yield take([ReconciliationTypes.mapReconciliation + "/INITIAL"]);
    yield put(ReconciliationActions.setProgressLabel("매핑중..."));
    const { mapReconciliationError } = yield race({
      success: take(ReconciliationActions.mapReconciliation.success),
      mapReconciliationError: take(
        ReconciliationActions.mapReconciliation.failure
      )
    });

    if (mapReconciliationError) {
      yield put(ReconciliationActions.setProgressLabel("매핑 실패"));
      yield delay(700);
      yield put(ReconciliationActions.truncateProgressLabel());
      return;
    }
    yield put(ReconciliationActions.setProgressLabel("매핑되었습니다."));
    yield delay(700);
    yield put(ReconciliationActions.truncateProgressLabel());
  }
}
