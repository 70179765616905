import { all, fork, put, take } from "redux-saga/effects";
import {
  AuthenticationActions,
  AuthenticationTypes
} from "../store/authentication";
import { createAsyncSaga } from "../cores/createAsyncSaga";
import { PayloadAction } from "typesafe-actions";
import { AxiosResponse } from "axios";
import { get } from "lodash";
import { ToastActions, ToastMessageTypes } from "../store/toast";
import {getCookieToken} from "../cores/getCookieToken";

export default function*() {
  yield all([
    ...createAsyncSaga(AuthenticationActions),
    getToken(),
    watchSetLogin(),
    watchSetLogout()
  ]);
}

export const tokenName = `heydealer-tracking`;

function* getToken() {
  const token = getCookieToken();
}

function* watchSetLogin() {
  while (true) {
    const {
      payload: { data }
    }: PayloadAction<
      typeof AuthenticationTypes.setLogin,
      AxiosResponse<{ token: string; user: { is_staff: boolean } }>
    > = yield take(AuthenticationActions.setLogin.success);

    const token = get(data, "token");
    const isStaff = get(data, "user.is_staff");

    if (!isStaff) {
      yield put(
        ToastActions.createToast({
          type: ToastMessageTypes.Error,
          message: "접근 권한이 없습니다."
        })
      );
    } else if (token) {
      localStorage.setItem(tokenName, token);
      yield put(AuthenticationActions.setToken(token));
      yield put(
        ToastActions.createToast({
          type: ToastMessageTypes.Success,
          message: "로그인 되었습니다."
        })
      );
    }
  }
}

function* watchSetLogout() {
  while (true) {
    yield take(AuthenticationTypes.setLogout);
    localStorage.removeItem(tokenName);
    yield fork(getToken);
  }
}
