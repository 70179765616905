import React from "react";
import styles from "./MonthPicker.module.scss";
import { ReactComponent as CalendarIcon } from "../../images/ic-calendar.svg";
import { ReactComponent as NextIcon } from "../../images/ic-next.svg";
import { ReactComponent as BeforeIcon } from "../../images/ic-before.svg";
import { DateTime } from "luxon";
import { useHistory, useLocation } from "react-router-dom";
import Ink from "react-ink";
import classNames from "classnames";

interface Props {
  month: string;
}

const MonthPicker: React.FC<Props> = ({month}) => {
  const history = useHistory();

  const onClickBefore = () => {
    if (month) {
      const nextMonth = DateTime.fromFormat(month, "yyyy-MM-dd").minus({
        month: 1
      });
      history.push(`?month=${nextMonth.toFormat("yyyy-MM-dd")}`);
    }
  };

  const onClickNext = () => {
    if (month) {
      const previousMonth = DateTime.fromFormat(month, "yyyy-MM-dd").plus({
        month: 1
      });
      history.push(`?month=${previousMonth.toFormat("yyyy-MM-dd")}`);
    }
  };
  return (
    <div className={styles.monthPicker}>
      <CalendarIcon className={styles.calendarIcon} />
      <div className={styles.month}>
        {month &&
          DateTime.fromFormat(month, "yyyy-MM-dd").toFormat("yyyy년 MM월")}
      </div>
      <div
        className={classNames(
          styles.arrowIconWrapper,
          styles.beforeIconWrapper
        )}
        onClick={onClickBefore}
      >
        <Ink />
        <BeforeIcon className={styles.beforeIcon} />
      </div>
      <div className={styles.arrowIconWrapper} onClick={onClickNext}>
        <NextIcon />
        <Ink />
      </div>
    </div>
  );
};

export default MonthPicker;
