import React from "react";

const DownloadIcon: React.FC<{}> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <filter
          id="prefix__a"
          width="111.7%"
          height="135%"
          x="-5.8%"
          y="-12.5%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.152941176 0 0 0 0 0.180392157 0 0 0 0 0.250980392 0 0 0 0.1 0"
          />
        </filter>
        <path id="prefix__b" d="M0 0H120V40H0z" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#10131a"
          d="M0 0H1680V2000H0z"
          transform="translate(-1553 -124)"
        />
        <g transform="translate(-1553 -124) translate(1540 116)">
          <use fill="#000" filter="url(#prefix__a)" />
          <path
            fill="#FFF"
            stroke="#E9EDF4"
            stroke-linejoin="square"
            d="M0.5 0.5H119.5V39.5H0.5z"
          />
          <g>
            <path
              fill="#10131a"
              fill-rule="nonzero"
              d="M8 17H16V18H8z"
              transform="translate(13 8)"
            />
            <path
              fill="#10131a"
              fill-rule="nonzero"
              d="M8 9H16V10H8z"
              transform="translate(13 8) rotate(90 12 9.5)"
            />
            <path
              stroke="#10131a"
              d="M7.5 10L12 14.5 16.5 10"
              transform="translate(13 8)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DownloadIcon;
