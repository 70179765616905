import React, { ReactNode } from 'react';
import styles from './Body.module.scss';

interface Props {
  children: ReactNode;
}

const Body: React.FC<Props> = ({children}) => {
  return (
    <div className={styles.body}>
      {children}
    </div>
  );
}

export default Body;