const categories = [
  {
    value: "missing_expenditure_evidence",
    label: "발행누락"
  },
  {
    value: "payed_failure",
    label: "선수금 발생"
  },
  {
    value: "refund",
    label: "환불"
  },
  {
    value: "tracked_failure",
    label: "이탈거래"
  },
  {
    value: "late_payment",
    label: "미입금건 입금"
  },
  {
    value: "amendment",
    label: "수정발행"
  },
  {
    value: "etc",
    label: "기타"
  },
  {
    value: null,
    label: "카테고리 선택"
  }
];
export default categories;
