import React, { FormEvent, useState } from "react";
import styles from "./Dialog.module.scss";
import { ReactComponent as ArrowDown } from "../../images/ic-toggle-down.svg";
import Ink from "react-ink";
import { map, find, includes } from "lodash";
import OutsideClickHandler from "react-outside-click-handler";
import classNames from "classnames";

interface Option {
  label: string;
  value: any;
}

interface Props {
  defaultValue?: any;
  options: Option[];
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
}

const Dialog: React.FC<Props> = ({
  defaultValue,
  options,
  onChange,
  onBlur
}) => {
  const [value, setValue] = useState(defaultValue);
  const [isOpen, setOpen] = useState(false);
  const getName = (value: any) => {
    const option = find(options, option => option.value === value);
    return option ? option.label : "카테고리 선택";
  };
  const onClickOptionMenu = (option: Option) => {
    setValue(option.value);
    setOpen(false);
    if (onChange) {
      onChange(option.value);
    }
  };

  const renderOptionMenu = (option: Option) => (
    <div
      className={styles.optionMenu}
      onClick={() => onClickOptionMenu(option)}
    >
      {option.label}
      <Ink />
    </div>
  );
  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className={styles.dialog}>
        <button
          className={classNames(
            styles.dialogInput,
            value === null ? styles.default : ""
          )}
          onClick={e => {
            e.preventDefault();
            setOpen(prev => !prev);
          }}
        >
          {getName(value)}
          <ArrowDown
            className={classNames(styles.arrow, isOpen ? styles.isOpen : "")}
          />
          <Ink />
        </button>
        {isOpen && (
          <div className={styles.options}>
            {map(options, option => renderOptionMenu(option))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Dialog;
