import React, {ReactNode} from "react";
import Content from "./Content/Content";
import Body from "./Body/Body";
import Header from "./Header/Header";

interface Props {
  children: ReactNode;
}

const Layout: React.FC<Props> = ({children}) => {
  return (
    <>
      <Header/>
      <Body>
        <Content>{children}</Content>
      </Body>
    </>
  );
};

export default Layout;
