import { all, call, put, take } from "redux-saga/effects";
import { CreateSequenceInput, SequenceTypes } from "../store/sequence/sequence";
import { PayloadAction } from "typesafe-actions";
import { get, isFunction, toArray } from "lodash";
import { createSuffixTypes } from "../cores/createAsyncSagaAction";

export default function* rootSaga() {
  yield all([watchCreateSequence()]);
}

function* watchCreateSequence() {
  while (true) {
    const createSequence: PayloadAction<
      SequenceTypes.createSequence,
      { sagaSequence: CreateSequenceInput; fallback?: () => void }
    > = yield take(SequenceTypes.createSequence);

    const { sagaSequence } = createSequence.payload;

    const failBack = get(createSequence.payload, "failBack");

    for (let i = 0, length = sagaSequence.length; i < length; i++) {
      const sequenceItem = sagaSequence[i];
      if (isFunction(sequenceItem)) {
        yield call(sequenceItem);
      } else {
        const action: PayloadAction<any, any> = yield put(sequenceItem);

        if (action.type.indexOf("/initial") !== -1) {
          const pureType = action.type.replace("/initial", "");
          const allTypes = createSuffixTypes(pureType);

          delete allTypes.initial;
          delete allTypes.original;
          delete allTypes.request;

          const response = yield take(toArray(allTypes));

          if (response.payload instanceof Error) {
            const toastMessage = get(response.payload, [
              "response",
              "data",
              "toast",
              "message"
            ]);

            if (failBack) {
              yield call(failBack);
            }

            break;
          }
        }
      }
    }
  }
}
