export function getPureType(type: string) {
  type = type
    .replace('/initial', '')
    .replace('/success', '')
    .replace('/failure', '')
    .replace('/cancel', '')
    .replace('_FULFILLED', '')
    .replace('_REJECTED', '');
  return type;
}
