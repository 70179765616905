import { all, delay, fork, put, select, take } from "redux-saga/effects";
import { Action } from "redux";
import { get, includes } from "lodash";
import { LoadingActions } from "../store/loading";

function getPureType(type: string) {
  type = type
    .replace("/INITIAL", "")
    .replace("/SUCCESS", "")
    .replace("/FAILURE", "")
    .replace("/CANCEL", "");
  return type;
}

export default function* rootSaga() {
  yield all([watchInitialAsyncActions(), watchFinalAsyncActions()]);
}

const loadingIgnoreActions: Array<string> = [];

function* watchInitialAsyncActions() {
  while (true) {
    const action = yield take((action: Action) => {
      return /\/INITIAL$/g.test(action.type);
    });
    const type = getPureType(action.type);
    if (!includes(loadingIgnoreActions, type)) {
      yield put(LoadingActions.setAsync(type, true));
    }
  }
}

function* watchFinalAsyncActions() {
  while (true) {
    const action = yield take((action: Action) => {
      return (
        /\/SUCCESS$/g.test(action.type) ||
        /\/FAILURE$/g.test(action.type) ||
        /\/CANCEL$/g.test(action.type)
      );
    });

    const type = getPureType(action.type);

    if (!includes(loadingIgnoreActions, type)) {
      yield put(LoadingActions.setAsync(type, false));
    }
  }
}
