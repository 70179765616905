const statusDisplay = {
  pending: "승인대기중",
  postpone: "승인보류중",

  approved: "경매진행중",
  ended: "선택대기중",

  selected: "연결대기",
  absence: "연결보류",

  contacted: "연결완료",

  scheduled: "거래예정",

  check: "확인예정",

  traded: "거래완료",
  expired: "유효기간만료",
  failed: "거래실패",
  refused: "승인거부",
  completed: "거래종결"
};

export default statusDisplay;
