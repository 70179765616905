import React, { memo, useCallback, useState } from "react";
import { DateTime } from "luxon";
import toDateFormat from "../../cores/toDateFormat";
import { toLocaleString } from "../../cores/toLocaleString";
import InputField from "../InputField/InputField";
import { get, toNumber } from "lodash";
import { Dangling } from "../../store/reconciliation/interface";
import { useDispatch } from "react-redux";
import { ReconciliationActions } from "../../store/reconciliation";
import Memo from "../Memo/Memo";
import styles from "./DanglingTableRow.module.scss";

interface Props {
  danglingItem: Dangling;
  index: number;
}

const DanglingTableRow: React.FC<Props> = memo(({ danglingItem, index }) => {
  const [carId, setCarId] = useState("");
  const amount = get(danglingItem, "amount");
  const name = get(danglingItem, "name");
  const issuedAt = get(danglingItem, "issued_at");
  const date = get(danglingItem, "date");
  const url = get(danglingItem, "url");
  const memo = get(danglingItem, "memo");
  const key = get(danglingItem, "key");
  const itemName = get(danglingItem, "item_name");
  const category = get(danglingItem, "category");
  const isOdd = index % 2 !== 0;

  const dispatch = useDispatch();

  const onKeyPressTextInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      e.currentTarget.blur();
    }
  };

  const onBlurCarId = useCallback(() => {
    const id = toNumber(carId);
    if (id) {
      dispatch(ReconciliationActions.mapReconciliation(toNumber(carId), key));
    }
  }, [carId, key]);

  return (
    <tr key={key} className={isOdd ? styles.odd : styles.even}>
      <td>
        {itemName ? (
          <a
            href={`https://admin.heydealer.com/cars?car_number=${itemName}&order=recent`}
            target="_blank"
          >
            {itemName}
          </a>
        ) : (
          "-"
        )}
      </td>
      <td>
        <a target="_blank" href={url}>
          {name}
        </a>
      </td>
      <td>{DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("yy-MM-dd")}</td>
      <td>{toDateFormat(issuedAt)}</td>
      <td>{toLocaleString(amount)}</td>
      <td>
        <InputField
          value={carId}
          onChange={e => {
            setCarId(e.currentTarget.value.replace(/[^0-9\.]+/g, ""));
          }}
          onBlur={onBlurCarId}
          onKeyDown={onKeyPressTextInput}
        />
      </td>
      <td>
        <Memo category={category} memo={memo} memoKey={key} />
      </td>
    </tr>
  );
});

export default DanglingTableRow;
