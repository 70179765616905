import React, { Fragment, memo, useEffect, useRef } from "react";
import classNames from "classnames";
import { drop, get, head, map } from "lodash";
import { Dangling, Delay } from "../../store/reconciliation/interface";
import statusDisplay from "../../cores/statusDisplay";
import { toLocaleString } from "../../cores/toLocaleString";
import toDateFormat from "../../cores/toDateFormat";
import styles from "./InconsistencyRow.module.scss";
import getPlusSign from "../../cores/getPlusSign";
import { useRouter } from "../../cores/useRouter";
import isSameWithCurrentMonth from "../../cores/isSameWithCurrentMonth";
import ExpenditureEvidenceRow from "../ExpenditureEvidenceRow/ExpenditureEvidenceRow";
import Memo from "../Memo/Memo";

interface Props {
  inconsistency: Delay;
  index: number;
}

const InconsistencyRow: React.FC<Props> = memo(({ inconsistency, index }) => {
  const { query } = useRouter();
  const currentMonth = query.get("month");
  const id = get(inconsistency, "id");
  const carNumber = get(inconsistency, "car_number");
  const status = get(inconsistency, "status");
  const fee = get(inconsistency, "fee");
  const isPayed = get(inconsistency, "is_payed");
  const payedAt = get(inconsistency, "payed_at");
  const tradeAt = get(inconsistency, "traded_at");
  const diff = get(inconsistency, "diff");
  const diffInTerm = get(inconsistency, "diff_in_term");
  const category = get(inconsistency, "category");
  const memo = get(inconsistency, "memo");
  const expenditureEvidences = get(inconsistency, "expenditure_evidences");
  const headExpenditureEvidence = head(expenditureEvidences);
  const exceptHeadExpenditureEvidences = drop(expenditureEvidences);
  const isOdd = index % 2 !== 0;

  const getExceptHeadExpenditureEvidenceRows = (
    expenditureEvidences: Dangling[]
  ) =>
    map(expenditureEvidences, expenditureEvidence => (
      <ExpenditureEvidenceRow expenditureEvidence={expenditureEvidence} />
    ));

  const exceptHeadExpenditureEvidenceRows = getExceptHeadExpenditureEvidenceRows(
    exceptHeadExpenditureEvidences
  );
  const expenditureEvidencesLength = expenditureEvidences.length;
  const rowSpan =
    expenditureEvidencesLength <= 1 ? 1 : expenditureEvidencesLength;

  return (
    <Fragment key={id}>
      <tr className={isOdd ? styles.odd : styles.even}>
        <td rowSpan={rowSpan}>
          {carNumber}&nbsp;
          <a target="_blank" href={`https://admin.heydealer.com/cars/${id}`}>
            ({id})
          </a>
        </td>
        <td
          rowSpan={rowSpan}
          className={status === "failed" ? styles.failed : ""}
        >
          {statusDisplay[status]}
        </td>
        <td rowSpan={rowSpan}>{toLocaleString(fee)}</td>
        <td rowSpan={rowSpan}>{isPayed ? "Y" : "N"}</td>
        <td rowSpan={rowSpan}>{toDateFormat(tradeAt)}</td>
        <td
          rowSpan={rowSpan}
          className={
            isSameWithCurrentMonth(currentMonth, payedAt)
              ? ""
              : styles.isNotCurrentMonth
          }
        >
          {toDateFormat(payedAt)}
        </td>
        <ExpenditureEvidenceRow expenditureEvidence={headExpenditureEvidence} />
        <td rowSpan={rowSpan} className={styles.tdStrongBorder}>
          <span
            className={classNames({
              [styles.plus]: diff > 0,
              [styles.minus]: diff < 0
            })}
          >
            {getPlusSign(diff)}
            {toLocaleString(diff)}
          </span>
        </td>
        <td rowSpan={rowSpan}>
          <span
            className={classNames({
              [styles.plus]: diffInTerm > 0,
              [styles.minus]: diffInTerm < 0
            })}
          >
            {getPlusSign(diffInTerm)}
            {toLocaleString(diffInTerm)}
          </span>
        </td>
        <td className={styles.tdStrongBorder} rowSpan={rowSpan}>
          <Memo category={category} memo={memo} id={id} />
        </td>
      </tr>
      {map(
        exceptHeadExpenditureEvidenceRows,
        (exceptHeadExpenditureEvidenceRow, index) => (
          <tr key={index} className={isOdd ? styles.odd : styles.even}>
            {exceptHeadExpenditureEvidenceRow}
          </tr>
        )
      )}
    </Fragment>
  );
});

export default InconsistencyRow;
