import React from "react";
import styles from "./Inconsistency.module.scss";
import Row from "../Row/Row";
import SumLabel from "../SumLabel/SumLabel";
import getPlusSign from "../../cores/getPlusSign";
import { toLocaleString } from "../../cores/toLocaleString";
import InconsistencyTable from "../InconsistencyTable/InconsistencyTable";
import { Delay } from "../../store/reconciliation/interface";

interface Props {
  inconsistencies: Delay[];
  diffByInconsistencies?: number;
}

const Inconsistency: React.FC<Props> = ({
  inconsistencies,
  diffByInconsistencies
}) => {
  return (
    <div className={styles.inconsistency}>
      <div className={styles.title}>합계 불일치 건</div>
      <Row>
        <SumLabel label="당월기준 차액 합계" subLabel="*작성/거래일 기준" />
        {getPlusSign(diffByInconsistencies)}
        {toLocaleString(diffByInconsistencies)}원
      </Row>
      <InconsistencyTable inconsistencies={inconsistencies} />
    </div>
  );
};

export default Inconsistency;
