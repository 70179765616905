import React, { Fragment } from "react";
import styles from "./ExpenditureEvidenceRow.module.scss";
import { get } from "lodash";
import { DateTime } from "luxon";
import toDateFormat from "../../cores/toDateFormat";
import { toLocaleString } from "../../cores/toLocaleString";
import isSameWithCurrentMonth from "../../cores/isSameWithCurrentMonth";
import { useRouter } from "../../cores/useRouter";
import { Dangling } from "../../store/reconciliation/interface";

interface Props {
  expenditureEvidence: Dangling | undefined;
}

const ExpenditureEvidenceRow: React.FC<Props> = ({ expenditureEvidence }) => {
  const amount = get(expenditureEvidence, "amount");
  const name = get(expenditureEvidence, "name");
  const issuedAt = get(expenditureEvidence, "issued_at");
  const url = get(expenditureEvidence, "url");
  const date = get(expenditureEvidence, "date");
  const key = get(expenditureEvidence, "key");
  const { query } = useRouter();
  const currentMonth = query.get("month");

  return (
    <Fragment key={key}>
      <td className={styles.tdStrongBorder}>
        <a target="_blank" href={url}>
          {name}
        </a>
      </td>
      <td
        className={
          isSameWithCurrentMonth(currentMonth, date)
            ? ""
            : styles.isNotCurrentMonth
        }
      >
        {date && DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("yy-MM-dd")}
      </td>
      <td>{toDateFormat(issuedAt)}</td>
      <td>{toLocaleString(amount)}</td>
    </Fragment>
  );
};

export default ExpenditureEvidenceRow;
