import React from "react";
import styles from "./SumLabel.module.scss";

interface Props {
  label: string;
  subLabel?: string;
}

const SumLabel: React.FC<Props> = ({ label, subLabel }) => {
  return (
    <div className={styles.label}>
      {label}&nbsp;
      <span className={styles.subLabel}>({subLabel})</span>
    </div>
  );
};

export default SumLabel;
