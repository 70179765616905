import { all, delay, fork, put, select, take } from "redux-saga/effects";
import {
  Toast,
  ToastActions,
  ToastMessageStatus,
  ToastMessageTypes,
  ToastTypes
} from "../store/toast";

export default function* rootSaga() {
  yield all([watchToastSaga()]);
}

function* endToast(toast: Toast) {
  yield delay(2000);
  yield put(ToastActions.setToastStatus(toast.id, ToastMessageStatus.Hide));
  yield delay(2000);
  yield put(ToastActions.deleteToast(toast.id));
}

function* watchToastSaga() {
  while (true) {
    yield take(ToastTypes.createToast);
    const toast = yield select(
      state => state.toast.toasts[state.toast.toasts.length - 1]
    );
    yield fork(endToast, toast);
  }
}
