import { combineReducers } from "redux";
import reconciliation from "./reconciliation";
import loading from "./loading";
import toast from "./toast";
import sequence from "./sequence/sequence";

const rootReducer = combineReducers({
  reconciliation,
  loading,
  toast,
  sequence
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
