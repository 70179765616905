import { DateTime } from "luxon";

const isSameWithCurrentMonth = (
  currentMonth: string | null,
  date: string | null | undefined
) => {
  if (currentMonth && date) {
    const currentDateTime = DateTime.fromFormat(currentMonth, "yyyy-MM-dd");
    return DateTime.fromSQL(date).hasSame(currentDateTime, "month");
  }
  return true;
};

export default isSameWithCurrentMonth;
