import React from "react";
import { map } from "lodash";
import { Dangling } from "../../store/reconciliation/interface";
import styles from "./DanglingTable.module.scss";
import DanglingTableRow from "../DanglingTableRow/DanglingTableRow";

interface Props {
  danglingItems: Dangling[];
}

const DanglingTable: React.FC<Props> = ({ danglingItems }) => {
  return (
    <>
      <table className={styles.danglingTable}>
        <thead>
          <tr>
            <th rowSpan={2} style={{ width: "10%" }}>
              차량번호
            </th>
            <th colSpan={4} style={{ width: "40%" }}>
              적격증빙
            </th>
            <th rowSpan={2} style={{ width: "5%" }}>
              차량 ID
            </th>
            <th rowSpan={2} style={{ width: "45%" }}>
              메모
            </th>
          </tr>
          <tr>
            <th>유형</th>
            <th>작성/거래 일자</th>
            <th>발행일시</th>
            <th>수수료</th>
          </tr>
        </thead>
        <tbody>
          {map(danglingItems, (danglingItem, index) => (
            <DanglingTableRow
              danglingItem={danglingItem}
              key={danglingItem.key}
              index={index}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default DanglingTable;
