import { all } from "redux-saga/effects";
import reconciliation from "./reconciliation";
import loading from "./loading";
import sequence from "./sequence";
import apiErrorHandling from "./apiErrorHandling";
import toast from "./toast";
import authentication from "./authentication";

export default function* rootSaga() {
  yield all([
    reconciliation(),
    loading(),
    sequence(),
    apiErrorHandling(),
    toast(),
    authentication()
  ]);
}
