import React, { useState } from "react";
import styles from "./Dangling.module.scss";
import DanglingTable from "../DanglingTable/DanglingTable";
import { Dangling as IDangling } from "../../store/reconciliation/interface";
import SumLabel from "../SumLabel/SumLabel";
import Row from "../Row/Row";
import { toLocaleString } from "../../cores/toLocaleString";
import getPlusSign from "../../cores/getPlusSign";

interface Props {
  danglingItems: IDangling[];
  diffByDangling: number | undefined;
}
const Dangling: React.FC<Props> = ({ danglingItems, diffByDangling }) => {
  return (
    <div className={styles.dangling}>
      <div className={styles.title}>매핑 실패 지출증빙</div>
      <Row>
        <SumLabel label="실적 합계" subLabel="*작성/거래일 기준" />
        {getPlusSign(diffByDangling)} {toLocaleString(diffByDangling)}원
      </Row>
      <DanglingTable danglingItems={danglingItems} />
    </div>
  );
};

export default Dangling;
