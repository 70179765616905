import React, { useEffect } from "react";
import { useTypedSelector } from "../cores/useTypedSelector";
import { get } from "lodash";
import RingLoader from "../components/RingLoader/RingLoader";
import styled from "styled-components";
import Layout from "../components/Layout";
import ReconciliationHeader from "../components/ReconciliationHeader/ReconciliationHeader";
import {
  ReconciliationActions,
  ReconciliationTypes
} from "../store/reconciliation";
import { SequenceActions } from "../store/sequence/sequence";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Dangling from "../components/Dangling/Dangling";
import Inconsistency from "../components/Inconsistency/Inconsistency";
import Delay from "../components/Delay/Dealy";
import { DateTime } from "luxon";

const LoaderContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Reconciliation: React.FC<{}> = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const month = query.get("month");
  const history = useHistory();
  const defaultMonth = DateTime.local()
    .minus({ month: 1 })
    .toFormat("yyyy-MM-01");
  const dispatch = useDispatch();

  useEffect(() => {
    if (month) {
      dispatch(
        SequenceActions.createSequence([
          ReconciliationActions.truncateReconciliationItems(),
          ReconciliationActions.getReconciliationItems(month)
        ])
      );
    } else {
      history.push(`?month=${defaultMonth}`);
    }
  }, [month]);

  const { reconciliation, reconciliationLoading } = useTypedSelector(
    ({ reconciliation: { reconciliation }, loading: { asyncMap } }) => ({
      reconciliation,
      reconciliationLoading:
        get(asyncMap, ReconciliationTypes.getReconciliationItems) > 0
    })
  );
  const summary = get(reconciliation, "summary");
  const danglingItems = get(reconciliation, "dangling");
  const inconsistencies = get(reconciliation, "inconsistencies");
  const delays = get(reconciliation, "delays");
  const diffByDangling = get(summary, "diff_by_dangling");
  const diffByInconsistencies = get(summary, "diff_by_inconsistencies");
  const diffByDelays = get(summary, "diff_by_delays");
  return (
    <Layout>
      <>
        {reconciliationLoading ? (
          <LoaderContainer>
            <RingLoader />
          </LoaderContainer>
        ) : (
          <>
            {summary && <ReconciliationHeader summary={summary} />}
            {danglingItems && danglingItems.length !== 0 && (
              <Dangling
                danglingItems={danglingItems}
                diffByDangling={diffByDangling}
              />
            )}
            {inconsistencies && inconsistencies.length !== 0 && (
              <Inconsistency
                inconsistencies={inconsistencies}
                diffByInconsistencies={diffByInconsistencies}
              />
            )}
            {delays && delays.length !== 0 && (
              <Delay delays={delays} diffByDelays={diffByDelays} />
            )}
          </>
        )}
      </>
    </Layout>
  );
};

export default Reconciliation;
