import { all, put, take } from "redux-saga/effects";
import { Action } from "redux";
import { get, includes } from "lodash";
import { getPureType } from "../cores/getPureType";
import { ToastActions, ToastMessageTypes } from "../store/toast";

export default function* rootSaga() {
  yield all([watchFailureActions()]);
}

const ignoreDefaultToastTypes: string[] = [];

function* watchFailureActions() {
  while (true) {
    const { type, payload } = yield take("*");
    if (payload instanceof Error) {
      const status = get(payload, ["response", "status"]);
      // 400번대 오류 발생한 API
      if (/4\d*/.test(status)) {
        const toastMessage = get(payload, [
          "response",
          "data",
          "toast",
          "message"
        ]);

        if (toastMessage) {
          if (includes(ignoreDefaultToastTypes, getPureType(type))) return;
          yield put(
            ToastActions.createToast({
              type: ToastMessageTypes.Error,
              message: toastMessage
            })
          );
        }
      }
    }
  }
}
