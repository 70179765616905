import React, { FC, HTMLAttributes } from "react";
import styles from "./InputField.module.scss";
import classNames from "classnames";

interface Props {
  type?: string;
  name?: string;
  label?: string;
  value?: string;
  className?: string;
  inputClassName?: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

const InputField: FC<Props & HTMLAttributes<HTMLInputElement>> = ({
  type = "text",
  name,
  label,
  value,
  className,
  inputClassName,
  ...otherProps
}) => {
  return (
    <label className={classNames(styles.inputField, className)}>
      {label && <span className={styles.label}>{label}</span>}
      <input
        data-testid="input"
        className={classNames(styles.input, inputClassName)}
        type={type}
        name={name}
        value={value}
        {...otherProps}
      />
    </label>
  );
};

export default InputField;
